import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import styled from "styled-components"

import { StyledAnchor } from "./common/navigation/style"

const WhatsAnchor = ({ className, children }) => {

  const phone = `https://s.tintim.app/whatsapp/7b4b3c2b-366e-4f88-bd7e-bf570c8767e0/1dd816ed-d838-4609-bd29-a3e58bc7a5b9`

  const fbTrackContact = () => {
    if (typeof window !== "undefined") {
      if (window.fbq != null) {
        window.fbq('track', 'Contact');
      }
    }
    console.log('click')
  }

  return (
    <StyledAnchor
      className={className}
      href={phone}
      onClick={() => {fbTrackContact()}}
      target="_blank"
      rel="noopener noreferrer"
    >
      {children}
    </StyledAnchor>
  )
}

export default WhatsAnchor

