import React from "react"
import styled from "styled-components"
// import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"

import { Section, Container } from "../global"
import Section2 from "../images/home_section_2"
import Section3 from "../images/home_section_3"
import Section4 from "../images/home_section_4"

const Features = () => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            models
            colors
          }
        }
      }
    `
  )

  return (
    <Section id="features">
      <Subtitle>Características</Subtitle>
      <StyledContainer>
        <FeatureItem>
          <ImageWrapper>
            <Section2></Section2>
            <br />
          </ImageWrapper>
          <FeatureTextGroup>
            <h2>Quantas camisetas precisar</h2>
            <ul>
              <li>Mais de {site.siteMetadata.models} modelos...</li>
              <li>Com {site.siteMetadata.colors} cores de tecido...</li>
              <li>E se desejar, tudo com estampas diferentes.</li>
            </ul>
          </FeatureTextGroup>
        </FeatureItem>
        <FeatureItem>
          <FeatureTextGroup>
            <h2>A qualidade do algodão</h2>
            <ul>
              <li>Na sublimação o processo também é digital, mas...</li>
              <li>
                A malha é sintética. Não dura e não transpira. Por isso...
              </li>
              <li>Usamos malha de algodão. Durável e confortável.</li>
            </ul>
          </FeatureTextGroup>
          <ImageWrapper>
            <Section3></Section3>
            <br />
          </ImageWrapper>
        </FeatureItem>
        <FeatureItem>
          <ImageWrapper>
            <Section4></Section4>
            <br />
          </ImageWrapper>
          <FeatureTextGroup>
            <h2>A flexibilidade do digital</h2>
            <ul>
              <li>A serigrafia também usa algodão, mas...</li>
              <li>
                Tem restrição de estampa e quantidade do pedido, por isso...
              </li>
              <li>
                Usamos digital. Não há restrição na quantidade e na estampa.
              </li>
            </ul>
          </FeatureTextGroup>
        </FeatureItem>
      </StyledContainer>
    </Section>
  )
}

export default Features

const StyledContainer = styled(Container)``

const FeatureTextGroup = styled.div`
  margin: 0 auto;
  padding-top: 16px;

  @media (max-width: ${props => props.theme.screen.md}) {
    padding-top: 0;
  }

  > div {
    width: 120%;
    margin-bottom: -4.5%;

    @media (max-width: ${props => props.theme.screen.md}) {
      margin: 0 16px;
    }
  }

  h1 {
    margin: 0 0 24px;
    color: ${props => props.theme.color.primary};
  }

  > ul {
    @media (max-width: ${props => props.theme.screen.md}) {
      padding-left: 0;
    }
  }

  p {
    margin-bottom: 48px;
  }

  li {
    margin-bottom: 16px;
    ${props => props.theme.font_size.small}
  }
`

const FeatureItem = styled.div`
  display: grid;
  justify-content: space-between;
  align-content: center;
  grid-template-columns: 1fr 1fr;

  @media (max-width: ${props => props.theme.screen.md}) {
    grid-template-columns: 1fr;
    grid-gap: 32px;
    margin-bottom: 10vh;
    text-align: center;
  }

  margin-bottom: 20vh;
`

const ImageWrapper = styled.div`
  justify-self: center;
  align-self: center;
  @media (max-width: ${props => props.theme.screen.md}) {
    justify-self: center;
    grid-row: 2;
  }
`

const Subtitle = styled.h5`
  font-size: 16px;
  color: ${props => props.theme.color.accent};
  letter-spacing: 0px;
  margin-bottom: 36px;
  text-align: center;
`
