import React from "react"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const Image = () => {
  const data = useStaticQuery(graphql`
    query {
      file(
        sourceInstanceName: { eq: "geral" }
        name: { eq: "exemplo-camisetas-estampadas" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  `)

  return (
    <StyledImage
      fluid={data.file.childImageSharp.fluid}
      alt="Exemplo de camisetas estampadas"
    />
  )
}

export default Image

const StyledImage = styled(Img)`
  width: 400px;
  @media (max-width: ${props => props.theme.screen.md}) {
    width: 300px;
  }
  @media (max-width: ${props => props.theme.screen.sm}) {
    width: 80vw;
  }
`
