import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

import { Container, Section } from "../global"
import WhatsAnchor from "../whatsanchor"

const CallToAction = ({ className, children, msg1, msg2, sub, link }) => {
  return (
    <StyledSection>
      <GetStartedContainer>
        <GetStartedTitle>
          {msg1}
          {msg2 != null ? <br /> : null}
          {msg2}
        </GetStartedTitle>
        <StyledLink to={'/'+link}>{children}</StyledLink>
        <Subtitle>({sub})</Subtitle>
      </GetStartedContainer>
    </StyledSection>
  )
}

export default CallToAction

const StyledSection = styled(Section)`
  background-color: ${props => props.theme.color.background.light};
  clip-path: polygon(0 0, 100% 5vw, 100% 100%, 0 calc(100% - 5vw));
`

const GetStartedContainer = styled(Container)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 80px 0 40px;

  @media (max-width: ${props => props.theme.screen.sm}) {
    padding: 40px 20px;
  }
`

const GetStartedTitle = styled.h3`
  margin: 0 auto 32px;
  text-align: center;
`

const Subtitle = styled.span`
  ${props => props.theme.font_size.xxxsmall}
  padding-top: 8px;
  color: ${props => props.theme.color.primary};
`

const StyledLink = styled(Link)`
  font-weight: 500;
  font-size: 14px;
  color: ${props => props.theme.color.white.regular};
  letter-spacing: 1px;
  height: 60px;
  display: block;

  text-transform: uppercase;
  cursor: pointer;
  white-space: nowrap;
  background: ${props => props.theme.color.accent};
  border-radius: 4px;
  padding: 0px 40px;
  border-width: 0px;
  border-style: initial;
  border-color: initial;
  border-image: initial;
  outline: 0px;

  &:hover {
    box-shadow: rgba(110, 120, 152, 0.55) 0px 2px 10px 0px;
  }
  &:disabled,
  &[disabled]{
    color: ${props => props.theme.color.black.lightest};
    background: ${props => props.theme.color.background.light};
  }
  @media (max-width: ${props => props.theme.screen.md}) {
  }
  @media (max-width: ${props => props.theme.screen.sm}) {
    margin-left: 0;
  }

  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
`