import React from "react"
import styled from "styled-components"

import Layout from "../components/common/layout/layout"
import SEO from "../components/common/layout/seo"
import Navigation from "../components/common/navigation/navigation"
import WhatsButton from "../components/common/whatsfloating"

import Header from "../components/sections/homeHeader"
import SocialProof from "../components/sections/socialproof"
import CallToAction from "../components/sections/calltoaction"
import Features from "../components/sections/features"
import Footer from "../components/sections/footer"

const IndexPage = () => (
  <Layout>
    <WhatsButton />
    <SEO />
    <Navigation />
    <Header
      header1="Estamparia de camisetas de algodão no digital."
      header2="Só o que você precisar."
      subheader="Nosso processo digital libera a restrição de quantidade nos
              pedidos. Quantas estampas, cores e tamanhos precisar."
    />
    <SocialProof />
    <CallToAction
      msg1="Defina cor, quantidade, estampa"
      msg2="e faça o seu pedido"
      sub="orçamento rápido e online"
      link="orcamento"
    >
      Simular
    </CallToAction>
    <Features />
    <CallToAction
      msg1="Entre em contato e faça um orçamento"
      sub="preços em segundos"
      link="orcamento"
    >
      Simular
    </CallToAction>
    <Footer />
  </Layout>
)

export default IndexPage