import React from "react"
import styled from "styled-components"

import { Section, Container } from "../global"

import Ouroboros from "../images/ouroboros"
import Callibu from "../images/callibu"
import Vermont from "../images/vermont"
import Bluewhaliens from "../images/bluewhaliens"

import B7 from "../images/b7"
import Wolf from "../images/wolf"
import Redsix from "../images/redsix"
import Geekcode from "../images/geekcode"

const SocialProof = () => (
  <Section id="socialproof">
    <StyledContainer>
      <Subtitle>Clientes</Subtitle>
      <SocialProofGrid>
        <SocialProofItem>
          <SocialProofTitle>Ouroboros</SocialProofTitle>
          <SocialProofLogo>
            <Ouroboros />
          </SocialProofLogo>
        </SocialProofItem>

        <SocialProofItem>
          <SocialProofTitle>Vermont</SocialProofTitle>
          <SocialProofLogo>
            <Vermont />
          </SocialProofLogo>
        </SocialProofItem>

        <SocialProofItem>
          <SocialProofTitle>Callibu</SocialProofTitle>
          <SocialProofLogo>
            <Callibu />
          </SocialProofLogo>
        </SocialProofItem>

        <SocialProofItem>
          <SocialProofTitle>Bluewhaliens</SocialProofTitle>
          <SocialProofLogo>
            <Bluewhaliens />
          </SocialProofLogo>
        </SocialProofItem>

        <SocialProofItem>
          <SocialProofTitle>B7</SocialProofTitle>
          <SocialProofLogo>
            <B7 />
          </SocialProofLogo>
        </SocialProofItem>

        <SocialProofItem>
          <SocialProofTitle>Wolf Companny</SocialProofTitle>
          <SocialProofLogo>
            <Wolf />
          </SocialProofLogo>
        </SocialProofItem>

        <SocialProofItem>
          <SocialProofTitle>Redsix</SocialProofTitle>
          <SocialProofLogo>
            <Redsix />
          </SocialProofLogo>
        </SocialProofItem>

        <SocialProofItem>
          <SocialProofTitle>Geek Code</SocialProofTitle>
          <SocialProofLogo>
            <Geekcode />
          </SocialProofLogo>
        </SocialProofItem>

      </SocialProofGrid>
    </StyledContainer>
  </Section>
)

export default SocialProof

const StyledContainer = styled(Container)``

const SocialProofGrid = styled.div`
  width: 80%;

  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(2, 1fr);
  row-gap:24px;
  
  margin: 0px auto;
  grid-column-gap: 40px;
  @media (max-width: ${props => props.theme.screen.sm}) {
    grid-template-columns: 1fr;
    grid-row-gap: 40px;
  }
`

const SocialProofItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const SocialProofTitle = styled.h4`
  color: ${props => props.theme.color.white.darker};
  letter-spacing: 0px;
  font-size: 26px;
  line-height: 30px;
  margin-bottom: 10px;
`

const SocialProofLogo = styled.div`
  position: relative;

  width: 100%;
  min-width: 75px;
  height: auto;

  // opacity: 0.85;
  border-radius: 10px;
  overflow: hidden;
`

const Subtitle = styled.h5`
  font-size: 16px;
  color: ${props => props.theme.color.accent};
  letter-spacing: 0px;
  margin-bottom: 12px;
  text-align: center;

  @media (max-width: ${props => props.theme.screen.sm}) {
    margin-bottom: 32px;
  }
`
